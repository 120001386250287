'use strict';

var app = require('angular').module('vaApp');
//app.directive('statement', ['$rootScope',require('./statementDirective')]);
//app.directive('sortable', ['$rootScope','$document',require('./sortableDirective')]);
app.directive('ng-sortable', [require('angular-legacy-sortablejs-maintained')]);
app.directive('spotEffect', [require('./audioDirective')]);
app.directive('ngScrollbar', [require('./scrollDirective')]);
app.directive('animationend', [require('./animationDirective')]);
app.directive('textsize', [require('./textSizeDirective')]);
app.directive('backImg', [require('./backdropDirective')]);
app.directive('ngMobileClick', [require('./touchDirective')]);
//app.directive('ng-animate', [require('angular-animate')]);
//app.module('myApp', [require('angular-animate')])