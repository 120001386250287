'use strict';
//venues
var controller = function($scope,$rootScope,$state,$stateParams,$timeout,dataFactory) {

	var data = {}
	console.log('ready',$stateParams)
	


	$rootScope.$on('dataSync', function() {
		
		if(dataFactory)
			data = dataFactory.getData();
		
		console.log('venue data',data.schedules[$stateParams.schedule])
		if(data.schedules && data.schedules[$stateParams.schedule]){
			loadVenue($stateParams.schedule,$stateParams.venue)
			//console.log('new venue',$scope.venue );
		}
		
	})


	$rootScope.$on('$stateChangeSuccess', function() {
       
      
       
     //  console.log('root state change start');
       
       
      // setUp()       
    })
    
    $scope.close = function(){
	    $state.go('schedules')
	    
    }
    
    $scope.recordDelivery = function(schedule,venue){
    
    	console.log('record',schedule,venue)
		$state.go('delivery',{schedule:schedule.id,venue:venue.id})
    
    }
    
    function loadVenue(schedule,venue){
	    
	    var venueID = venue+'_'+schedule;
	    
	    $scope.schedule = dataFactory.load('schedules',schedule)
	    $scope.venue = dataFactory.load('venues',venueID)
	   
	    
    }
    
    function init(){
	   // data = dataFactory.getData();
	  //  console.log('init venues',data)	
	    loadVenue($stateParams.schedule,$stateParams.venue)    
    }    
	
	
	init();
};

module.exports = controller;