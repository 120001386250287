'use strict';

var controller = function($scope,$rootScope,$state,$stateParams,$window,$timeout,dataFactory) {

	$rootScope.serverBASE = window.serverURL;
	$rootScope.serverURL = $rootScope.serverBASE+'/api'

	function notification(text,level){
		
		
		
		$scope.notification = text;
		
		$timeout(function(){$scope.notification = false},1000);
		
		
	}
	
	$rootScope.$on('notification', function(event,text,level) {
		notification(text,level)		
	});
	
	$scope.status = {
		report:'no updates',
		state:'offline'
	}
	
	$scope.refresh = function(){
		
		
		//dataFactory.putData();
		
		 dataFactory.refreshData(7,function(){
			    console.log('local caching')
			   
		    })
	}
	
	
	$scope.showProfile = function(){

		$state.go('profile');
	}

    
    $rootScope.$on('dataSync', function() {
		
		var date = new Date();
		
		$scope.status.report = 'Last update ' + date.getHours()+':'+date.getMinutes();
		notification('data downloaded')

	})
    
    function connectivity(event){
	    
	    var networkState = navigator.connection.type;
    	
    	if(event && event.type == 'offline'){
	    	$scope.networkStatus = 0;
	    	$rootScope.$broadcast( "network", 0 ); 
	    	notification('offline')	    	
    	}
    	
    	if(event && event.type == 'online'){
	    	$scope.networkStatus = 1;
	    	$rootScope.$broadcast( "network", 1 ); 	
	    	notification('online')    	
    	}
    	
    	if(event && event.type == 'deviceready'){
	    	
	    	if(networkState != 'none'){
		    	$scope.networkStatus = 1;
		    	$rootScope.$broadcast( "network", 1 ); 	
		    	notification('online')
	    	}
	    		
    	}
    	
    	$scope.$apply();
  
	    
    }
    
    function init(){
	    
	    var token = $window.localStorage.getItem('token')
	    
		if(!token){
			$timeout(function(){$state.go('login')},500);
			
		}else{

			dataFactory.init(token);
			
		    dataFactory.refreshData(7,function(){ })
		    
		    //dataFactory.recover();
		    

		}
		
	    $scope.appVersion = 'version 1.0.0';
	    $scope.buildVersion = window.buildVersion;
	    
	    $rootScope.buildVersion = window.buildVersion;
	    
	    if(cordova && cordova.getAppVersion)
		    cordova.getAppVersion.getVersionNumber().then(function (version) {
			    $timeout(function() {
			          
			          $scope.appVersion = 'v '+ version;
			    }, 0);
		   });
	    	    
	    document.addEventListener("deviceready", connectivity, false);
	    
	    document.addEventListener("offline", connectivity, false);	
	    document.addEventListener("online", connectivity, false);		 
	    
	
	    
	    	
    }

	
	
	init();
	
};

module.exports = controller;