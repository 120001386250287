'use strict';

var upload = function($q){
	
	var formData = new FormData();
	var collection = [];
	
	
	
	
	this.pickFiles = function(image){
	    var defer = $q.defer();//Create a PROMISE
	//openup Camera to Capture files
		formData = new FormData();
	
		//for (var c=0; c< images.length;c++){
	
		    window.resolveLocalFileSystemURL(image, function(fileEntry) {
		        fileEntry.file(function(file) {
		            var reader = new FileReader();
		
		            //add to collection for thumbnail views
		            collection.push(file.nativeURL);
		
		            reader.onloadend = function(fileReadResult) {
		                var data;
		                data = new Uint8Array(fileReadResult.target.result);
		                //add to formData for submitting
		                formData.append('file[]', new Blob([data], {
		                    type: file.type
		                    }), file.name);
						console.log('appending',file.name);
		                //return [0]=>collection of thumbnails [1]=>formData to be posted
		                defer.resolve([collection,formData]);
		            };
		
		            defer.notify('got image, appending it to formData');
		            reader.readAsArrayBuffer(file);
		        });
		
		    });
	    
		//}
	
	return defer.promise;
	}
}

module.exports = upload;