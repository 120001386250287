'use strict';

var controller = function($scope,$rootScope,$state,$stateParams,$timeout,dataFactory) {

	var data = {};
	//console.log('ready')

	$scope.toggles = {};
	
	$scope.close = function(){
		
		$state.go('schedules');
	}
	
	$scope.toggle = function(order){
		console.log('toggle',$scope.toggles)
		if($scope.toggles[order.id])
			$scope.toggles[order.id] = false;
		else
			$scope.toggles[order.id] = true;
		
	}

	$rootScope.$on('dataSync', function() {
		
		loadSchedules();

	})


	$rootScope.$on('$stateChangeSuccess', function() {
       
      
       
     //  console.log('state change',$stateParams);
       
      
       
       init()       
    })
    
    
    $scope.toggleDispatch = function(order){
	   
	   
	    
	   if(order.status >= 3){
		   order.status = 2;
	   }else if(order.status <= 2){
		   order.status = 3
	   }
	   
	   
	   
	    order.dispatched = !order.dispatched;
	    dataFactory.dispatch(order);
	
	    
    }
    
    
    function loadSchedules(){
	    var data = dataFactory.load('schedules');
		$scope.schedules = data;
	    console.log('loaing the data')
    }
    

	function init(){
		
		loadSchedules();
		
	} 
	
	
	init();
	
};

module.exports = controller;