'use strict';

var fileSystem = require('../lib/fileSystem');

var delviery = {
	id:null,
	title:null,
	schedule_id:null,
	route_id:null,
	user_id:null,
	admin_id:null,
	date:null,
	status:null,
	sigbase:null,
	photobase:null,
	proof:null,
	photo:null,
	print:null,
	orders:[]
}
//delivery
var controller = function($scope,$rootScope,$window,$state,$stateParams,$timeout,dataFactory,siUpload) {

	var data = {schedules:null};
	//console.log('ready')

	$rootScope.$on('dataSync', function() {
		
		//data = dataFactory.getData();
	//	console.log('new delivery ',data);
		
		if($stateParams.venue)
			loadDelivery($stateParams.schedule,$stateParams.venue)
		

	})
	

	var saveIt = function(data){
		
		console.log('saving deslivery ' ,JSON.stringify(data))
		dataFactory.save('deliveries',data.id,data,true);
	}
	
	
	
	$scope.record = function(){
		
		
		var getSignature = $scope.accept().dataUrl;
		
		
		var dataChange = false
		
		if(getSignature){
			
			if(window.device && window.device.platform != 'browser'){
					dataChange = true;

					window.canvas2ImagePlugin.saveImageDataToLibrary(
				        function(filepath){
				            console.log('saved to '+filepath);  //msg is the filename path (for android and iOS)
				            $scope.delivery.sigbase = 'file://'+filepath;
				           
				            var filename = filepath.split('/')
				            $scope.delivery.proof = filename[filename.length-1];
				             $scope.$apply();
				            //dataFactory.record($stateParams.schedule,$stateParams.venue,$scope.delivery);
				            saveIt($scope.delivery)
				            //dataFactory.save('deliveries',$scope.delivery.id,$scope.delivery);
							
				        },
				        function(err){
				            console.log('failed sig',err,document.getElementById('signatureCanvas').children[0]);
				            saveIt($scope.delivery)
				        },
				        document.getElementById('signatureCanvas').children[0],
				        '.jpg',
				        80
				    );
			}else{
				$scope.delivery.sigbase = 'assets/images/dog.png';
				//dataFactory.record($stateParams.schedule,$stateParams.venue,$scope.delivery);
				saveIt($scope.delivery);
			}
			
			
			
		}else{
				saveIt($scope.delivery);
				//dataFactory.record($stateParams.schedule,$stateParams.venue,$scope.delivery);

		}
		
		$state.go('venue',{schedule:$stateParams.schedule,venue:$stateParams.venue})
		
		
		
	}
	
	$scope.check = function(evt){
		
		console.log('key',evt)
		
		if(evt.key == 'Enter'){
			var target = evt.target;
			target.blur();
		}
		
	}
	
	$scope.photo = function(){

		navigator.camera.getPicture(function(imageSrc){
			console.log('captured photo',imageSrc)
			
			var filename = imageSrc.split('/')
			$scope.delivery.photo = filename[filename.length-1];
			$scope.delivery.photobase = imageSrc;	
			$scope.photoPreview	= imageSrc;	
			$scope.$apply();
			
		}, function(error){
			console.log('failed because',error)
		}, { 
		    quality: 50,
		
		    targetHeight : 420,
		    destinationType: Camera.DestinationType.FILE_URI
		});		
	}
	
	$scope.close = function(){
	    $state.go('venue',{schedule:$stateParams.schedule,venue:$stateParams.venue})
	    
    }
	
	$scope.newSig = function(){
		
		$scope.isSigning = true
	}
	
	
	function create(schedule,venue){
		
		var newDelivery = angular.copy(delviery);
			newDelivery.title = venue.address.title;
			newDelivery.schedule_id = schedule.id;
			newDelivery.venue_id = venue.id;
			newDelivery.user_id = $window.localStorage.getItem('id');
			newDelivery.id = schedule.id+'_'+venue.id;
					
		//for(var c=0;c< venue.orders.length; c++){
		//	newDelivery.orders.push(venue.orders[c].id)
		//}
		
		
		return newDelivery;

		
		
	}
	
	 function loadDelivery(schedule_id,venue_id){
	    
	    $scope.signaturePreview = false;
	    $scope.photoPreview = false;
	    $scope.isSigning = true
	    
	    
	    var schedule = dataFactory.load('schedules',schedule_id);
	    var venue = dataFactory.load('venues',venue_id+'_'+schedule_id);
	    
	    if(venue && venue.delivery !== null){
		   $scope.delivery =  dataFactory.load('deliveries',venue.delivery);
		   
		   if($scope.delivery.sigbase){
					$scope.signaturePreview = $scope.delivery.sigbase;
					$scope.isSigning = false;
					}
		
					
			if($scope.delivery.photobase)
				$scope.photoPreview = $scope.delivery.photobase;
		   
	    }else{
		    $scope.delivery = create(schedule,venue);
	    }
	    
	    $scope.delivery.orders = [];
	    
	    for(var c=0;c< venue.orders.length; c++){
			$scope.delivery.orders.push(venue.orders[c].id)
		}
	    
	    console.log($scope.delivery);

    }
	
	function init(){
	   // data = dataFactory.getData();
	    loadDelivery($stateParams.schedule,$stateParams.venue)	    
    }    
	
	
	init();
	
	
};

module.exports = controller;