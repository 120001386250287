'use strict';

var directive = function($parse, $window) {
    return {
      restrict: 'A',
      replace: false,
     
      scope: { ngScrollbar: '=' },
      link: function (scope, element, attrs) {
	  	
	  	var selected = false;
	  	var elem = element[0];
	  	
	  	var scrollArea = document.getElementById(scope.ngScrollbar);
	  	
	  	console.log('called!',elem)
	  	
	  	var selected = null, // Object of the element to be moved
		    x_pos = 0, y_pos = 0, // Stores x & y coordinates of the mouse pointer
		    x_elem = 0, y_elem = 0, // Stores top, left values (edge) of the element
		    bounds = []; 
		
		// Will be called when user starts dragging an element
		function _drag_init(elem) {
		    // Store the object of the element which needs to be moved
		    selected = elem;
		    x_elem = x_pos - selected.offsetLeft;
		    
		    bounds[0] = selected.parentNode.offsetLeft;
		    bounds[1] = selected.parentNode.offsetWidth - selected.offsetWidth;
		    
		  //  y_elem = y_pos - selected.offsetTop;
		}
		
		// Will be called when user dragging an element
		function _move_elem(e) {
			//console.log('move?')
		    x_pos = document.all ? window.event.clientX : e.pageX;
		   // y_pos = document.all ? window.event.clientY : e.pageY;
		    var x = (x_pos - x_elem)
		    if (selected !== null && x >= 0 && x <= bounds[1]) {
		        selected.style.left = x + 'px';
		        
		        scrollArea.style.left =  (0 - x * 3.2) + 'px';
		        
		      //  selected.style.top = (y_pos - y_elem) + 'px';
		    }
		}
		
		// Destroy the object when we are done
		function _destroy() {
		    selected = null;
		}
		

		
		$(elem.parentNode).on('touchstart mousedown', elem, function(e){
			e.preventDefault();
            e.stopPropagation();
			_drag_init(elem);
		})
		
		// Bind the functions...
		$(elem.parentNode).on('touchmove mousemove', elem, function(e){
			
			e.preventDefault();
            e.stopPropagation();
			var evt = e;
			
			if(e.touches)
				evt = e.touches[0];

			_move_elem(evt);
		})
		
		$(elem.parentNode).on('touchend mouseup', elem, function(e){
			console.log('end')
			_destroy();
		})
		
		document.onmousemove = _move_elem;
		//document.onmouseup = _destroy;

      }
    };
  }

module.exports = directive;
