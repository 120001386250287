'use strict';


require('es5-shim');



var angular = require('angular');

var neighbour = require('nearest-neighbor');
require('angular-ui-router');


require('angular-animate');

require('angular-signature');



var app = angular.module('vaApp', ['ui.router','ng-sortable','ngAnimate','ngSignaturePad']);

require('./services/');
require('./controllers/');
require('./directives/');

app.config(

		function($stateProvider, $urlRouterProvider) {

		    $urlRouterProvider.otherwise('/app');
		    
		    $stateProvider
		        
		        // HOME STATES AND NESTED VIEWS ========================================
		         .state('app', {
		            url: '/',
		            params: {
			            route: null
			        }
		        })
		        .state('profile', {
		            url: '/profile',
		            params: {
			            route: null
			        },
		            templateUrl: 'views/profile.html'
		        })
		        .state('login', {
		            url: '/login',
		            params: {
			            route: null
			        },
		            templateUrl: 'views/login.html'
		        })
		        .state('schedules', {
		            url: '/schedules',
		            params: {
			            route: null
			        },
		            templateUrl: 'views/schedules.html'
		        })
		        .state('orders', {
		            url: '/orders',
		            params: {
			            order: null
			        },
		            templateUrl: 'views/orders.html'
		        })
		        .state('venue', {
		            url: '/venue/:schedule/:venue',
		            params: {
			            venue: null,
			            schedule:null
			        },
		            templateUrl: 'views/venue.html'
		        })
		        .state('delivery', {
		            url: '/delivery/:schedule/:venue',
		            params: {
			            venue: null,
			            schedule:null
			        },
		            templateUrl: 'views/delivery.html'
		        })
		        .state('score', {
		            url: '/route/:route',
		            templateUrl: 'app/views/profile.html'
		        });
		        
		}
)




