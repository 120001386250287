'use strict';

var controller = function($scope,$rootScope,$state,$stateParams,$timeout,dataFactory) {


	//console.log('ready')

	$rootScope.$on('dataSync', function() {
		
		loadSchedules()
		//$scope.apply();
		//console.log('new schedules',data);
	})


	$rootScope.$on('$stateChangeSuccess', function() {
       
     
    })
    
    function loadSchedules(){
	    var data = dataFactory.load('schedules');
		console.log('schedules is',data);
		$scope.schedules = data; //data.schedules;
	    
    }
    
	function init(){
		
		loadSchedules();
		
	} 
	
	
	$scope.dispatch = function(orders){
		
		
		console.log('dispatch',orders)
	}
	
	$scope.showVenue = function(schedule,venue){
		
	//	console.log('go to venue',schedule.id,venue.id)
		$state.go('venue',{schedule:schedule.id,venue:venue.id})
		
	}
	
	
	init();
	
};

module.exports = controller;