'use strict';

var factory = function($http,$rootScope,$window,siUpload) {

   /*
   	var request = {},
   		connectionStatus = 0,
   		retrySync = null,
   		lastConnection = 0,
   		data = {},
   		remote = {schedules:null,orders:null},
   		local = [],
   		orders = [],
   		uploads = [],
   		token = null;
   */
   
   var 	request = {},
   		connectionStatus = 0,
   		token = null,
   		data = {}, //local data
   		remote = {},
   		queue = {}, //to be synced
   		uploads = [], //files
   		urlBase = window.serverURL+'/api';
   
   

   
   var pull = function(){

   		return $http.get(urlBase+'/get')
        .then(function(res){
	        
	        if(typeof res.data == 'object'){
				
				update(res.data);
            	
            }else{
	            $rootScope.$broadcast( "notification", 'error recieving data'); 
            }
            
                    
        });

   	}
   	
   var uploadQueue = function(files){
	     
	     	//uploads = []
	     
		 	for(var c=0;c<uploads.length; c++){
		   		siUpload.pickFiles(uploads[c]).then(
				function(r){
					var fd = r[1];
					console.log(r);
					$http({
				        method: 'POST',
					    url: urlBase+'/upload',
					    headers: {'Content-Type': undefined},
					    data: fd
					}).success(function (err,result) {
						console.log('uploaded ',err,result,uploads)
						$rootScope.$broadcast( "notification", 'photos uploaded' ); 
						//uploads = []
						
					});
					
					
				})
			}   
	   
	   
   }
   	
   var push = function(){
	   	
	   	
	   	if(queue.deliveries.length > 0 ){
		   	console.log('found items', JSON.stringify(queue.deliveries));
		   	
		   
		   	
		   	for(var c=0; c<queue.deliveries.length; c++){
			    
			    if(queue.deliveries[c].sigbase && !uploads.includes(queue.deliveries[c].sigbase)){
			    	uploads.push(queue.deliveries[c].sigbase)
			    	
			    	}
			    else
			    	console.log('already queued',queue.deliveries[c].sigbase )
			    	
			    if(queue.deliveries[c].photobase && !uploads.includes(queue.deliveries[c].photobase)){
			    	uploads.push(queue.deliveries[c].photobase)
			    	}
			    else
			    	console.log('already queued',queue.deliveries[c].photobase )	    
		    }
		    
		    
		    console.log('uploads', uploads);
		   	
		   	$http({
			        method: 'POST',
				    url: urlBase+'/put',
				    headers: {'Content-Type': 'application/x-www-form-urlencoded'},
				    data: queue.deliveries
				}).success(function (result,err) {
					console.log('sent data',queue,err,'data',result)
					$rootScope.$broadcast( "notification", 'data uploaded' ); 
					
					if(result && result.complete === 'true'){
						//local = [];
						for(var key in result.deliveries){
							console.log('remove',result[key],key);
							//delete queue.deliveries[key];
							dequeue('deliveries',key);
						}
						console.log('queue',queue);
					}else{
						//offline();
					}
					
					//backupData();
				});
	   	}
	   	
	   if(uploads.length > 0 ){
		   	console.log('found uploads', uploads);
		   	uploadQueue(uploads);
		}
	   	
   	}
   	
   	
   var update = function(updates){
	   	
	   	if(updates.schedules){
		   	
		   	for(var key in updates.schedules){
			   //	console.log(updates.schedules[key]);
			   	save('schedules',key,updates.schedules[key]);
			   	
			   for(var ley in updates.schedules[key].venues){

				   	
				   	var delivery = null;
				   	
				   	var venueID = updates.schedules[key].venues[ley].id+'_'+updates.schedules[key].id;
					save('venues',venueID,updates.schedules[key].venues[ley]);
				   		
				   	for(var mey in updates.schedules[key].venues[ley].orders){
					   //	console.log(updates.schedules[key].venues[ley].orders[mey]);
					   	save('orders',updates.schedules[key].venues[ley].orders[mey].id,updates.schedules[key].venues[ley].orders[mey]);
					   	
					   	if(updates.schedules[key].venues[ley].orders[mey].delivery !== null){
						   	updates.schedules[key].venues[ley].delivery =  updates.schedules[key].venues[ley].orders[mey].delivery.id;
						   	updates.schedules[key].venues[ley].orders[mey].delivery.venue_id = ley;
						   	save('venues',venueID,updates.schedules[key].venues[ley]);
						   	save('deliveries',
						   		updates.schedules[key].venues[ley].orders[mey].delivery.id,
						   		updates.schedules[key].venues[ley].orders[mey].delivery);
					   	}
					}
					
					
					
				   	
				}
			   	
		   	}
		   	
	   	}
	   	repopulate();
	   	console.log('data is',data)
   	}
   	
   	
   	var repopulate = function(){
	   	
	   	
	   	
	   	if(queue.deliveries && queue.deliveries.length > 0){
		   	
		   	for(var c=0;c<queue.deliveries.length;c++){
			   	var deliveryRecord = queue.deliveries[c];
			   	console.log('q',queue.deliveries[c]);
			   	if(data.deliveries[deliveryRecord.schedule_id] && data.deliveries[deliveryRecord.schedule_id].venues[deliveryRecord.venue_id]){
				   	data.deliveries[deliveryRecord.schedule_id].venues[deliveryRecord.venue_id].delivery = deliveryRecord.id;
			   	}
		   	}
		   	
	   	}
	   	
	   	if(queue.orders && queue.orders.length > 0){
		   		console.log('q',queue.orders.length);
		   	
		   //	for(var c=0;c<queue.orders.length;c++){
			   	
			  // 	var orderRecord = queue.orders[c];
			   console.log('found',data.schedules);
			   	
			   	for(var key in data.schedules){
				   	console.log('scheduld',data.schedules[key])
				   	for(var ley in data.schedules[key].venues){
				   		
					   	for(var d=0;d < data.schedules[key].venues[ley].orders.length; d++){
						   	
						   	for(var c=0;c<queue.orders.length;c++){
							   
							   if(data.schedules[key].venues[ley].orders[d].id == queue.orders[c].id){
							   		data.schedules[key].venues[ley].orders[d].status = queue.orders[c].status;
							   }	
							   	
							}
			
					   	
					   	}
				   	
				   	}
			   	}
		   	
	   	}
	   	
   	}
   	
   var recover = function(){
	   	
	   	var stored =  JSON.parse($window.localStorage.getItem('queue'));
	   	if(stored && (stored.deliveries || stored.orders)){
	   		queue = stored;
	   		console.log('recovered',queue)
	   		repopulate();
	   		
	   	}
	   	
	   	
	   	
   }
   
   
   var backup = function(){
	   	
	   	console.log('backup',queue)
	   	$window.localStorage.setItem('queue',JSON.stringify(queue));
	   	
   	}
   	
   	
   var refresh = function(){
	   
	   pull();
	   
   }
   	
   var init = function(newToken){
	   	
	   	data = {
			 schedules:{},
			 venues:{},
			 orders:{},
			 deliveries:{}
			 
		};
		
		queue = {
			deliveries: [],
			orders: []
		}
		
		recover();
		
		token = newToken;
	    $http.defaults.headers.common['Authorization'] = "Bearer " + token;  
	   	
   	}
   
   var dispatch = function(order){
	   
	   queue.orders.push(order);
	   console.log('add order',order.status,queue);
	   backup();
	 
	   
	    $http({
		        method: 'POST',
			    url: urlBase+'/dispatch',
			    headers: {'Content-Type': 'application/x-www-form-urlencoded'},
			    data: queue.orders
			}).success(function () {
				console.log('sent dispatches',queue.orders)
				$rootScope.$broadcast( "notification", 'order dispatched' ); 
				queue.orders = [];
				backup();
			});
	   	 
   }
   	
   var save = function(type,key,value,fresh){
	  // console.log('saving called',type,value)
	   var timestamp = Math.round((new Date()).getTime() / 1000);
	   
	   if(typeof data[type][key] != undefined){
		   
		   data[type][key] = value;
		   
	   }else{
		   
		   data[type][key] = value;
		   
	   }  
	   
	   if(type === 'deliveries'){
		   console.log('add ',value.schedule_id+'_'+value.venue_id)
		   data['venues'][value.venue_id+'_'+value.schedule_id].delivery = value.id;
		   
		   
	   }
	   
	   if(fresh === true){
		   
		   if(type === 'deliveries'){
			   enqueue('deliveries',value.id ,value);
			   console.log('queue',queue);
			   push();
		   }
		   
	   }
	   		
	   
	   
	   
   }
   
   
   var enqueue = function(type,key,value){
	   
	   var exists = false;
	   
	   for(var c=0;c < queue[type].length;c++){
		   if(queue[type][c].id == value.id){
			   exists = true;
			   queue[type][c] = value;
		   }
	   }
	   
	   if(!exists){
		   queue[type].push(value);
	   }
	   backup();
	   
   }
   
    var dequeue = function(type,key){
	   
	   var exists = false;
	   
	   for(var c=0;c < queue[type].length;c++){
		   console.log('keuy',key,queue[type][c])
		   if(queue[type][c].id == key){
			   exists = true;
			   queue[type].splice(c,1);
		   }
	   }
	   
	   backup();
	   
   }
   
   var load = function(type,key){
	   
	   
	    if(key === undefined){
		    return data[type];
	    }
	   
	   
	    if(typeof data[type][key] != undefined){
		
		   	return data[type][key];
		
		}else{
			
			return null;
			
		}
	   
   }
   
              
    return {
	    init:init,
	    save:save,
	    load:load,
	    refreshData:refresh,
	    dispatch:dispatch
    };
    
}

module.exports = factory;

