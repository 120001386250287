'use strict';

var directive = function() {
	return {
		restrict: 'A',
		scope: {
			animationend: '&'
		},
		link: function(scope, element) {
			var callback = scope.animationend(),
				  events = 'animationend webkitAnimationEnd MSAnimationEnd' +
						'transitionend webkitTransitionEnd';

			element.on(events, function(event) {
				if(event.target == element[0])
					callback.call(element[0], event);
			});
		}
	};
};

module.exports = directive;