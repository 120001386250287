'use strict';

var app = require('angular').module('vaApp');



app.controller('AppController', ['$scope','$rootScope','$state','$stateParams','$window','$timeout','dataFactory',require('./AppController')]);
app.controller('LoginController', ['$scope','$rootScope','$state','$stateParams','$http','$window','$timeout','dataFactory',require('./LoginController')]);

										//$scope,$rootScope,$state,$stateParams,$timeout,dataFactory
app.controller('SchedulesController', ['$scope','$rootScope','$state','$stateParams','$timeout','dataFactory',require('./SchedulesController')]);
app.controller('OrdersController', ['$scope','$rootScope','$state','$stateParams','$timeout','dataFactory',require('./OrdersController')]);

//                                    $scope,$rootScope,$state,$stateParams,$timeout,dataFactory
app.controller('VenuesController', ['$scope','$rootScope','$state','$stateParams','$timeout','dataFactory',require('./VenuesController')]);

app.controller('DeliveryController', ['$scope','$rootScope','$window','$state','$stateParams','$timeout','dataFactory','siUpload',require('./DeliveryController')]);

//$scope,$rootScope, router, session