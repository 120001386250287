'use strict';

var directive =  function () {
    return {
        //restrict: 'A',
        scope: {
			textsize: '&'
		},
        link: function (scope, element, attrs) {
			console.log('scope',scope.textsize)
			scope.$parent.$watch('slogan', function(newValue, oldValue) {
				console.log('change to ',newValue);
			})

        }
    };
};

module.exports = directive;