'use strict';

var directive = function () {
    return {
        restrict: 'A',
        transclude:false,
        scope: {
			backImg: '&'
		},
        link: function (scope, element, attrs){
        console.log(attrs);
        var url = attrs.backImg;
        element.css({
            'background-image': 'url("/uploads/' + url +'/product.png")',
            'background-size' : 'cover'
        });
    }
    };
};

	
module.exports = directive;