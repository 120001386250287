'use strict';

var controller = function($scope,$rootScope,$state,$stateParams,$http,$window,$timeout,dataFactory) {


	var urlBase = window.serverURL+'/api';

	function init(){
		var user = $window.localStorage.getItem('profile')
		
		if(user){
			console.log('is user',JSON.parse(user))
			$scope.user = JSON.parse(user);
		}else{
			$state.go('login')
		}
		
	}
	
	$scope.close = function(){
		
		$state.go('schedules')
	}

	$scope.logout = function(){
		
		$window.localStorage.removeItem('token');
		$window.localStorage.removeItem('profile');
		
		$state.go('login')
	}

	$scope.login = function(){
		
		console.log($scope.user);
		
		 $http({
		        method: 'POST',
			    url: urlBase+'/login',
			    headers: {'Content-Type': 'application/x-www-form-urlencoded'},
			    data: $scope.user
			}).success(function (response) {
				console.log('result is data',response)
				
				if(response.token && response.token != false){
					
					var profile = {
						'username':response.username,
						'name':response.name,
						'email':response.email,
						'login':new Date().toJSON().slice(0, 19).replace('T', ' ')
					};
					
					$window.localStorage.setItem('token',response.token)
					$window.localStorage.setItem('profile',JSON.stringify(profile));
					$window.localStorage.setItem('id',response.id)
					$rootScope.$broadcast( "login", response.token );
					$timeout(function(){$state.go('schedules')},500); 	  					
				}
				
			});
		
	}
	
	init()
	
};

module.exports = controller;