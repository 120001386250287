'use strict';

var directive =  function () {
    return {
        restrict: 'A',
        scope: {
            spotEffect: '='
        },
        link: function (scope, element, attrs, ngModelCtrl) {

            var removeBehaviorsRestrictions = function() {
                element.load();
                window.removeEventListener('keydown', removeBehaviorsRestrictions);
                window.removeEventListener('mousedown', removeBehaviorsRestrictions);
                window.removeEventListener('touchstart', removeBehaviorsRestrictions);
            };
            window.addEventListener('keydown', removeBehaviorsRestrictions);
            window.addEventListener('mousedown', removeBehaviorsRestrictions);
            window.addEventListener('touchstart', removeBehaviorsRestrictions);

            scope.spotEffect = element[0];
            console.log('called',scope.spotEffect);
            
            cueEffect = function(){
	            
	            //preload
	            console.log('loading audio');
	            
	            
            }
            
            playEffect = function(){
	            
	            //preload
	            console.log('loading audio');
	            
	            
            }
            
            scope.setEffect = function(src){
	            
	            scope.spotEffect.src = src;
	            
            }
        }
    };
};

module.exports = directive;