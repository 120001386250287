var fileService = (function () {
    
    var myFolderApp = 'LDDS'
    
    
    var create = function(imageString){
	    console.log(imageString);
	    var blob = b64toBlob(imageString, 'image/png');
	    
	    saveBlob(myFolderApp, blob, "test.png");
	    
    }
    
    function saveBlob(dirEntry, fileData, fileName) {
	
	    dirEntry.getFile(fileName, { create: true, exclusive: false }, function (fileEntry) {
	
	        writeBlob(fileEntry, fileData);
	
	    }, onErrorCreateFile);
	}
	
	function writeBlob(fileEntry, dataObj, isAppend) {
	
	    // Create a FileWriter object for our FileEntry (log.txt).
	    fileEntry.createWriter(function (fileWriter) {
	
	        fileWriter.onwriteend = function() {
	            console.log("Successful file write...");
	            if (dataObj.type == "image/png") {
	                readBinaryFile(fileEntry);
	            }
	            else {
	                readFile(fileEntry);
	            }
	        };
	
	        fileWriter.onerror = function(e) {
	            console.log("Failed file write: " + e.toString());
	        };
	
	        fileWriter.write(dataObj);
	    });
	}
    
    
    var save = function(file){
	    
	    console.log('save it',file);
	    window.resolveLocalFileSystemURI(file, resolveOnSuccess, resOnError); 
	    
    }
    
    
    function resolveOnSuccess(entry){ 
	    var d = new Date();
	    var n = d.getTime();
	    //new file name
	    var newFileName = n + ".png";

	
	    window.requestFileSystem(LocalFileSystem.PERSISTENT, 0, function(fileSys) {      
	    //The folder is created if doesn't exist
	    fileSys.root.getDirectory( myFolderApp,
	                    {create:true, exclusive: false},
	                    function(directory) {
	                        entry.moveTo(directory, newFileName,  successMove, resOnError);
	                    },
	                    resOnError);
	                    },
	    resOnError);
	}
	
	function successMove(entry) {
	    console.log('I saved to ',entry)
	}
	
	function resOnError(error) {
	    console.log('failed', error.code);
	}
	
	function b64toBlob(b64Data, contentType, sliceSize) {
	    contentType = contentType || '';
	    sliceSize = sliceSize || 512;
	
	    var byteCharacters = atob(b64Data);
	    var byteArrays = [];
	
	    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
	        var slice = byteCharacters.slice(offset, offset + sliceSize);
	
	        var byteNumbers = new Array(slice.length);
	        for (var i = 0; i < slice.length; i++) {
	            byteNumbers[i] = slice.charCodeAt(i);
	        }
	
	        var byteArray = new Uint8Array(byteNumbers);
	
	        byteArrays.push(byteArray);
	    }
	
	    var blob = new Blob(byteArrays, {type: contentType});
	    return blob;
	}
    
    
    return {
	    
	    save:save,
	    create:create
	    
    }
})();

module.exports = fileService;